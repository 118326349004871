//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import { inviteService } from "@/service/inviteService";
	import clause from './clause';
	export default {
		components: {
			clause
		},
		computed: {
			phoneTxt() {
				return this.userInfo.phoneNumber.substr(0, 7)
				//				return this.userInfo.phoneNumber.replace(this.userInfo.phoneNumber.substring(3, 7), "****")
			}
		},
		data() {
			return {
				userInfo: {
					name: '唐国强',
					phoneNumber: "18356070195",
					isAgreeDisclaimer: false,
					hospital: "", // 医院
					department: "" // 科室
				},
				phoneVal: "", // 手机号后四位数
				verifyCode: '', // 验证码
				isChecked: false, // 条款CheckBox
				captchaSended: false, // 验证码是否已发送
				countDown: 60, // 验证码倒计时
			}
		},
		created() {
			if(this.$route.query.confId) {
				this.iStorage.remove("iconfId")
				this.iStorage.set("meetingId", this.$route.query.confId.split("|")[0]);
				this.iStorage.set("confId", this.$route.query.confId.split("|")[1]);
			}
			if(this.$route.query.iconfId) {
				this.iStorage.remove("confId")
				this.iStorage.set("meetingId", this.$route.query.iconfId.split("|")[0]);
				this.iStorage.set("iconfId", this.$route.query.iconfId.split("|")[1]);
			}
			this.QueryInvitation();
		},
		methods: {
			// 获取参会人信息
			QueryInvitation() {
				var params;
				if(this.iStorage.get('confId')) {
					params = {
						InvitationId: this.iStorage.get('confId')
					}
				} else {
					params = {
						InspectorId: this.iStorage.get('iconfId')
					}
				}
				inviteService.QueryInvitation(params).then(response => {
					if(response.success) {
						this.userInfo = response.content;
					}
				});
			},
			// 发送验证码
			SendCaptcha() {
				if(!this.phoneVal) {
					this.$iToast("请补全后4位手机号");
					this.$refs.input1.focus()
					return false;
				}
				if(this.phoneTxt + this.phoneVal != this.userInfo.phoneNumber) {
					this.$iToast({
						message: "您录入的手机号码不正确，请重新录入",
						time: 4000
					});
					this.$refs.input1.focus()
					return false;
				}
				var params;
				if(this.iStorage.get('confId')) {
					params = {
						InvitationId: this.iStorage.get('confId')
					}
				} else {
					params = {
						InspectorId: this.iStorage.get('iconfId')
					}
				}
				inviteService.SendCaptcha(params).then(response => {
					if(response.success) {
						this.captchaSended = true;
						this.countDown = 60;
						var setIntervalId = setInterval(() => {
							this.countDown--;
							if(this.countDown < 0) {
								window.clearInterval(setIntervalId);
								this.captchaSended = false;
							}
						}, 1000)
					}
				});
			},
			onConfirm() {
				if(!this.phoneVal) {
					this.$iToast("请补全后4位手机号");
					this.$refs.input1.focus()
					return false;
				}
				if(this.phoneTxt + this.phoneVal != this.userInfo.phoneNumber) {
					this.$iToast({
						message: "您录入的手机号码不正确，请重新录入",
						time: 4000
					});
					this.$refs.input1.focus()
					return false;
				}
				if(!this.verifyCode) {
					this.$iToast("请输入手机验证码");
					this.$refs.input2.focus()
					return false;
				}
				// 库里未同意，且点击了同意
				if(!this.userInfo.isAgreeDisclaimer && this.isChecked) {
					this.AgreeDisclaimer();
				}
				this.CheckCaptcha();
			},
			// 验证验证码
			CheckCaptcha() {
				var params;
				if(this.iStorage.get('confId')) {
					params = {
						InvitationId: this.iStorage.get('confId'),
						Captcha: this.verifyCode
					}
				} else {
					params = {
						InspectorId: this.iStorage.get('iconfId'),
						Captcha: this.verifyCode
					}
				}
				inviteService.CheckCaptcha(params).then(response => {
					if(response.success) {
						this.JoinMeeting();
					}
				});
			},
			// 获取url
			JoinMeeting() {
				var params;
				if(this.iStorage.get('confId')) {
					params = {
						InvitationId: this.iStorage.get('confId'),
						MettingId: this.iStorage.get('meetingId')
					}
				} else {
					params = {
						InspectorId: this.iStorage.get('iconfId'),
						MettingId: this.iStorage.get('meetingId')
					}
				}
				inviteService.JoinMeeting(params).then(response => {
					if(response.success) {
						let uName = `Ⓐ${this.userInfo.name}（${this.userInfo.hospital ? this.userInfo.hospital + '-' + this.userInfo.department : ""}）`
						window.location.href = `${response.content.joinUrl}?uname=${encodeURI(uName)}`;
					}
				});
			},
			// 同意免责申明
			AgreeDisclaimer() {
				var params;
				if(this.iStorage.get('confId')) {
					params = {
						InvitationId: this.iStorage.get('confId')
					}
				} else {
					params = {
						InspectorId: this.iStorage.get('iconfId')
					}
				}
				inviteService.AgreeDisclaimer(params).then(response => {
					if(response.success) {
						console.log("同意免责申明", response)
					}
				});
			},
			back() {
				this.$router.go(-1);
			}
		}
	}
