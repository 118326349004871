//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	// 隐私条款
	import privacyPolicy from './privacyPolicy';
	// 申明
	import declare from './declare';
	// 全屏
	import dialog from './dialog';
	import { iConstructor } from "@/core";
	export default {
		components: {
			privacyPolicy,
			declare
		},
		model: {
			prop: 'visible',
			event: 'customModelEvent'
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			visible() {
				this.isChecked = this.visible;
			},
			isChecked() {
				this.$emit("customModelEvent", this.isChecked)
			}
		},
		created() {
			this.isChecked = this.visible;
		},
		data() {
			return {
				isChecked: false,
				componentName: 'privacyPolicy'
			}
		},
		methods: {
			fullScreen() {
				iConstructor.show(Object.assign({
					// 需要打开的目标组件。
					el: dialog,
					// 目标组件关闭的监听回调方法，
					// 目标组件关闭时，执行this.$emit("close", message)就可以触发onClose,可以参考TaskLog.vue里面的代码。
					onClose: function(res) {
						console.log(res); //关闭需要执行的逻辑
					}.bind(this)
				}, {
					componentName: this.componentName,
					title: this.componentName == 'privacyPolicy' ? "隐私条款" : "申明"
				}))
			}
		}
	}
