function injectStyle (context) {
  require("!!vue-style-loader!css-loader?minimize!../../../../node_modules/vue-loader/lib/style-compiler/index?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-d55a7ab4\",\"scoped\":true,\"sourceMap\":false}!sass-loader!../../../../node_modules/vux-loader/src/style-loader.js!../../../../node_modules/vue-loader/lib/selector?type=styles&index=0!./privacyPolicy.vue")
}
/* script */
export * from "!!babel-loader!../../../../node_modules/vux-loader/src/script-loader.js!../../../../node_modules/vux-loader/src/script-loader.js!../../../../node_modules/vue-loader/lib/selector?type=script&index=0!./privacyPolicy.vue"
import __vue_script__ from "!!babel-loader!../../../../node_modules/vux-loader/src/script-loader.js!../../../../node_modules/vue-loader/lib/selector?type=script&index=0!./privacyPolicy.vue"
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-d55a7ab4\",\"hasScoped\":true,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../../../node_modules/vux-loader/src/before-template-compiler-loader.js!../../../../node_modules/vux-loader/src/template-loader.js!../../../../node_modules/vue-loader/lib/selector?type=template&index=0!./privacyPolicy.vue"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = injectStyle
/* scopeId */
var __vue_scopeId__ = "data-v-d55a7ab4"
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)

export default Component.exports
