//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	// 隐私条款
	import privacyPolicy from './privacyPolicy';
	// 申明
	import declare from './declare';
	export default {
		components: {
			privacyPolicy,
			declare
		},
		model: {
			prop: 'visible',
			event: "dialogListener"
		},
		props: {
			visible: { //控制弹出、关闭
				type: Boolean,
				default: false
			},
			width: {
				type: [String, Number],
				default: 80
			},
			title: { //提示
				type: String,
				default: "提示"
			},
			componentName: {
				type: String,
				default: "privacyPolicy"
			},
			// 操作按钮
			buttons: {
				type: Array,
				default: () => [{
					txt: "我知道了",
					code: "hasRead"
				}]
			},
			closeModal: { //是否点击遮罩关闭iMessage
				type: Boolean,
				default: true
			}
		},
		watch: {
			visible() {
				this.isShow = this.visible;
			},
			isShow() {
				this.$emit("dialogListener", this.isShow);
			}
		},
		data() {
			return {
				isShow: false,
				zIndex: 30000
			}
		},
		created() {
			this.isShow = this.visible;
		},
		methods: {
			showPopup(options) {
				Object.assign(this.$props, options);
				this.isShow = true;
			},
			clickBtn(item) {
				this.cancel();
			},
			//取消
			cancel() {
				this.isShow = false;
				this.$emit("close");
			},
			//是否点击遮罩关闭messageBox
			closeOnClick() {
				this.closeModal && this.cancel();
			}
		}
	}
